import {Box, Dialog, DialogContent, DialogTitle, Grid, Link, Tooltip, Typography} from "@material-ui/core";
import ReplyIcon from "@material-ui/icons/Reply";
import moment from "moment";
import {LabelValue} from "../../components/LabelValue";
import {PaperItem} from "../../components/PaperItem";
import {StatusLabel} from "../../components/Status";

import {faMessage, faTrash} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EditIcon from "@material-ui/icons/Edit";
import ErrorIcon from "@material-ui/icons/Error";
import LinkIcon from "@material-ui/icons/Link";
import classnames from "classnames";
import React from "react";
import HtmlRender from "../../../js/components/HtmlRender";
import {QuestionAnswer} from "../../components/AI";
import {ConfirmationDialog} from "../../components/Dialogs";
import {AIQuestionForm} from "../../components/forms/AIForms";
import InnerFooter from "../../components/InnerFooter";
import {MuiNavLink} from "../../components/Links";
import {ReactTableMui} from "../../components/ReactTableMui";
import {makeNovoClasses} from "../../theme";

export const WorkPlanPaperItem = (props: {workPlan: any}) => {
  const {workPlan} = props;

  return (
    <PaperItem>
      <PaperItem.Body>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <StatusLabel status={workPlan.status_display} hint="workPlan" />{" "}
            <MuiNavLink to={workPlan.url}>{workPlan.type_display}</MuiNavLink>
          </Grid>
          <Grid item xs={12} sm={4}>
            <LabelValue label="Project" value={workPlan.project.display} component="span" />
          </Grid>

          <Grid item xs={12} sm={4}>
            <LabelValue label={"Created By"} value={workPlan.created_by.full_name} component="span" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LabelValue label="Subcontractor" value={workPlan.subcontractor.name} component="span" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LabelValue label="Dates" value={`${workPlan.start_date} - ${workPlan.end_date}`} component="span" />
          </Grid>

          <Grid item xs={12}>
            <LabelValue label="Comments" value={workPlan.comments} />
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right minWidth={35}></PaperItem.Right>
      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton icon={LinkIcon} title="Link" component={Link} href={workPlan.url} />
      </PaperItem.RightHover>
    </PaperItem>
  );
};

export const WorkPlanCommunicationPaperItem = (props: {
  communication: any;
  onClickReply: () => void;
  onEdit: () => void;
  replyTo: any;
  [rest: string]: any;
}) => {
  const {communication, onClickReply, replyTo, onEdit, ...rest} = props;
  return (
    <PaperItem {...rest}>
      <PaperItem.Body>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <LabelValue
              label="From"
              value={communication.is_auto_generated ? "Sentinel" : communication.created_by.full_name}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LabelValue label="To" value={communication.recipient?.full_name} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LabelValue label="Created" value={moment(communication.created).format("YYYY-MM-DD hh:mm A")} />
          </Grid>
          <Grid item xs={12}>
            <HtmlRender html={communication.comments} />
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.RightHover>
        {replyTo && <PaperItem.RightHover.IconButton icon={ReplyIcon} title="Reply" onClick={onClickReply} />}
        {!communication.is_closed && <PaperItem.RightHover.IconButton icon={EditIcon} title="Edit" onClick={onEdit} />}
      </PaperItem.RightHover>
    </PaperItem>
  );
};

export const WorkPlanTable = (props: {workPlans: any[]}) => {
  const {workPlans} = props;
  const novoClasses = makeNovoClasses();
  const [showComments, setShowComments] = React.useState(false);
  const [activeWorkPlan, setActiveWorkPlan] = React.useState({} as {comments: string});
  return (
    <>
      <ReactTableMui
        getHeaderProps={(column) => {
          if (["project.display", "type_display"].includes(column.id)) {
            return {
              style: {
                width: "180px",
              },
            };
          }
          if (["start_date", "end_date"].includes(column.id)) {
            return {
              style: {
                width: "100px",
              },
            };
          }
        }}
        columns={[
          {
            Header: "Status",
            accessor: "status",
            Cell: ({row}) => <StatusLabel status={row.original.status_display} hint="workPlan"></StatusLabel>,
          },
          {
            Header: "Type",
            accessor: "type_display",
            Cell: ({row, value}) => (
              <Link href={row.original.url} underline="always">
                {value}
              </Link>
            ),
          },
          {Header: "Project", accessor: "project.display"},
          {
            Header: "Start",
            accessor: "start_date",
          },
          {Header: "End", accessor: "end_date"},
          // {
          //   Header: "Created By",
          //   accessor: "created_by.full_name",
          // },
          {Header: "Subcontractor", accessor: "subcontractor.name"},

          {
            Header: "Last Communication",
            accessor: "last_communication",
            Cell: ({value}) => (value ? moment(value).format("YYYY-MM-DD hh:mm A") : ""),
          },
          {
            Header: "Expedited",
            accessor: "is_expedited",
            Cell: ({row, value}) =>
              value ? (
                <Box component="span" display="flex" justifyContent="center">
                  <ErrorIcon fontSize="small" color="error" />
                </Box>
              ) : null,
            sortType: "basic",
          },
          {
            accessor: "comments",
            id: "comments",
            disableSortBy: true,
            Cell: ({row, value}) => {
              if (!value) return null;
              return (
                <Tooltip title="View Comments">
                  <FontAwesomeIcon
                    icon={faMessage}
                    onClick={() => {
                      setActiveWorkPlan(row.original);
                      setShowComments(true);
                    }}
                  />
                </Tooltip>
              );
            },
          },
        ]}
        data={workPlans}
        size="small"
        className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
        hover
        usePagination
        initialState={{
          sortBy: [
            {id: "is_expedited", desc: true},
            {id: "start_date", asc: true},
          ],
          pageSize: 50,
        }}
      />
      <Dialog open={showComments} onClose={() => setShowComments(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Comments</DialogTitle>
        <DialogContent>
          <HtmlRender html={activeWorkPlan.comments} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export const WorkPlanAnswers = (props: {
  workPlan: any;
  answerQuery: any;
  onDelete: (answerId: number) => Promise<any>;
  onCreate: (values: {question: string; work_plan: number}) => Promise<any>;
  [rest: string]: any;
}) => {
  const {workPlan, answerQuery, onDelete, onCreate, ...rest} = props;
  // const workPlanId = workPlan.id;

  const [deleteOtherAnswerConfirmationIsOpen, setDeleteOtherAnswerConfirmationIsOpen] = React.useState(false);
  const [activeAnswer, setActiveAnswer] = React.useState(null);
  const [pendingQuestions, setPendingQuestions] = React.useState([]);

  const allAnswers = answerQuery.data.results;

  const otherAnswers = allAnswers.filter(
    (answer) => !["general_checklist", "plan_checklist"].includes(answer.prompt_code)
  );
  const generalChecklist = allAnswers.find((answer) => answer.prompt_code === "general_checklist");
  const planChecklist = allAnswers.find((answer) => answer.prompt_code === "plan_checklist");

  const scrollToBottomTargetRef = React.useRef(null);
  const scrollToBottom = () => {
    setTimeout(() => {
      scrollToBottomTargetRef.current?.scrollIntoView({
        behavior: "smooth",
        inline: "end",
      });
    }, 100);
  };
  const TargetBox = React.forwardRef((props, ref) => {
    return <Box height={75} ref={ref} {...props} />;
  });

  // const generalAnswer = JSON.parse(generalChecklist.answer);
  // const planAnswer = JSON.parse(planChecklist.answer);
  const generalAnswer = generalChecklist ? JSON.parse(generalChecklist.answer) : {};
  const planAnswer = planChecklist ? JSON.parse(planChecklist.answer) : {};

  return (
    <>
      {generalChecklist && (
        <>
          <PaperItem.Header>
            <PaperItem.Body>
              <h3 style={{margin: 0}}>General Checklist</h3>
            </PaperItem.Body>
          </PaperItem.Header>

          <Box py={1} px={2}>
            {Object.entries(generalAnswer).map(([question, value]) => {
              return (
                <React.Fragment key={question}>
                  <Typography style={{fontWeight: value ? "normal" : "bold"}} key={question}>
                    {question} {value ? "Yes" : "No"}
                  </Typography>
                </React.Fragment>
              );
            })}
            {/* <ReactMarkdown>{generalChecklist.answer}</ReactMarkdown> */}
          </Box>
        </>
      )}
      {planChecklist && (
        <>
          <PaperItem.Header>
            <PaperItem.Body>
              <h3 style={{margin: 0}}>{workPlan.type_display} Checklist</h3>
            </PaperItem.Body>
          </PaperItem.Header>

          <Box py={1} px={2}>
            {Object.entries(planAnswer).map(([question, value]) => {
              return (
                <React.Fragment key={question}>
                  <Typography style={{fontWeight: value ? "normal" : "bold"}} key={question}>
                    {question} {value ? "Yes" : "No"}
                  </Typography>
                </React.Fragment>
              );
            })}
            {/* <ReactMarkdown>{planChecklist.answer}</ReactMarkdown> */}
          </Box>
        </>
      )}
      {(otherAnswers.length > 0 || pendingQuestions.length > 0) && (
        <>
          <PaperItem.Header borderTop={1}>
            <PaperItem.Body>
              <h3 style={{margin: 0}}>Other Questions</h3>
            </PaperItem.Body>
          </PaperItem.Header>

          {otherAnswers.map((answer, i) => {
            return (
              <QuestionAnswer key={answer.id}>
                <QuestionAnswer.Item text={answer.question}>
                  <Tooltip title="Delete Question">
                    <Box
                      onClick={() => {
                        setActiveAnswer(answer);
                        setDeleteOtherAnswerConfirmationIsOpen(true);
                      }}
                      style={{cursor: "pointer"}}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Box>
                  </Tooltip>
                </QuestionAnswer.Item>
                <QuestionAnswer.Item text={answer.answer} response />
              </QuestionAnswer>
            );
          })}
        </>
      )}

      {pendingQuestions.map((text) => {
        return (
          <QuestionAnswer key={text}>
            <QuestionAnswer.Item text={text} />
            <QuestionAnswer.Item text={undefined} response />
          </QuestionAnswer>
        );
      })}
      <TargetBox ref={scrollToBottomTargetRef} />
      <InnerFooter>
        <AIQuestionForm
          onSubmit={(values) => {
            setPendingQuestions([...pendingQuestions, values.question]);
            scrollToBottom();
            onCreate({question: values.question, work_plan: workPlan.id}).then(() => {
              setPendingQuestions(pendingQuestions.filter((question) => question !== values.question));
              scrollToBottom();
            });
          }}
          initialValues={{}}
        />
      </InnerFooter>

      <ConfirmationDialog
        isOpen={deleteOtherAnswerConfirmationIsOpen}
        onApprove={() => {
          onDelete(activeAnswer.id).then(() => {
            setDeleteOtherAnswerConfirmationIsOpen(false);
            setActiveAnswer(null);
          });
        }}
        onDeny={() => setDeleteOtherAnswerConfirmationIsOpen(false)}
      >
        You want to delete this question and answer?
      </ConfirmationDialog>
    </>
  );
};
