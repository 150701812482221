import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../api";
import {UploadFileButton} from "../../components/Buttons";
import {ConfirmationDialog} from "../../components/Dialogs";
import Typography2 from "../../components/Typography2";
import {
  CheckboxMui,
  DatePickerMui,
  EmployeeAutoselectMui,
  ProjectAutoselectMui,
  ProjectCompanyAutoselectMui,
  SimpleSelect,
  TextFieldMui,
  TinyMCEMui,
  UserAutoselectMui,
} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";
import workPlanTypes from "../../components/forms/choices/workPlanTypes.json";
import useBlockUI from "../../hooks/useBlockUI";
import moment from "moment";

export const WorkPlanForm = (props: {
  onSubmit: (values: any) => void;
  isNew?: boolean;
  workPlan: any;
  onUpload: () => Promise<any>;
  onDelete: () => void;
  workPlanDocuments: any[];
  [rest: string]: any;
}) => {
  const {onSubmit, isNew, workPlan, onUpload, workPlanDocuments, onDelete, ...rest} = props;
  const blockUI = useBlockUI();

  const document = workPlanDocuments[0];

  const [reUploadConfirmationDialogIsOpen, setReUploadConfirmationDialogIsOpen] = React.useState(false);
  const [fileToUpload, setFileToUpload] = React.useState(null);

  const onUploadPlan = (file) => {
    blockUI.blockUI("Analyzing with AI...");
    let formData = new FormData();
    formData.append("file", file);
    return axiosAPI
      .post(`/safety/work-plans/${workPlan.id}/upload/`, formData)
      .then((response) => {
        onUpload().then(() => {
          window.location.href = `#tab=questions`;
          blockUI.unblockUI();
        });
      })
      .catch((error) => {
        blockUI.unblockUI();
        console.error(error);
      });
  };

  return (
    <>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {({handleSubmit, form, submitting, pristine, values}) => (
          <>
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <>
                  <Grid container spacing={1}>
                    <WorkPlanCommonFields
                      isNew={isNew}
                      projectId={workPlan?.project?.id}
                      isReadOnly={workPlan.is_closed}
                      workPlan={workPlan}
                    />
                    <Grid item xs={12}>
                      <Box textAlign="right">
                        {!workPlan.is_closed && (
                          <UploadFileButton
                            disabled={workPlan.is_closed}
                            onUpload={(file) => {
                              if (!document) {
                                onUploadPlan(file);
                              } else {
                                setFileToUpload(file);
                                setReUploadConfirmationDialogIsOpen(true);
                              }
                            }}
                          >
                            {document ? `Re-` : ``}Upload Work Plan
                          </UploadFileButton>
                        )}
                        {document && !workPlan.is_closed && (
                          <div>
                            <Typography2 type="metadata">{document.name}</Typography2>
                          </div>
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <TinyMCEMui name="comments" label="Comments" disabled={workPlan.is_closed} required />
                    </Grid>

                    <Grid item xs={12}>
                      <Box display="flex" flexDirection={"row-reverse"}>
                        {!workPlan.is_closed && <FormActions.SaveButton disabled={submitting || pristine} />}
                        <Box mr={1} />
                        {workPlan.can_delete && <FormActions.DeleteButton onClick={onDelete} />}
                      </Box>
                    </Grid>
                  </Grid>
                </>
              </FinalFormKeyboardShortcuts>
            </form>
          </>
        )}
      </FinalForm>
      <ConfirmationDialog
        onApprove={() => {
          onUploadPlan(fileToUpload).then(() => {
            setReUploadConfirmationDialogIsOpen(false);
          });
        }}
        onDeny={() => {
          setReUploadConfirmationDialogIsOpen(false);
        }}
        isOpen={reUploadConfirmationDialogIsOpen}
      >
        You want to re-upload the work plan? This will overwrite the existing work plan and you will lose any AI
        analysis and questions that have been generated. Are you sure you want to continue?
      </ConfirmationDialog>
    </>
  );
};

export const WorkPlanCommunicationDialogForm = (props) => {
  const {isOpen, onSubmit, handleClose, isNew, ...rest} = props;

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          handleClose();
        }}
        maxWidth="md"
        fullWidth
      >
        <FinalForm onSubmit={onSubmit} keepDirtyOnReinitialize {...rest}>
          {({handleSubmit, form, submitting, pristine, values, errors, dirtyFields}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">
                  {`${isNew ? "Create" : "Update"} Work Plan Communication`}
                </DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <UserAutoselectMui name="created_by" label="From" disabled />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <UserAutoselectMui name="recipient" label="To" required />
                      </Grid>
                      <Grid item xs={12}>
                        {/* <TextFieldMui name="comments" label="Comments" minRows={3} multiline required /> */}
                        <TinyMCEMui name="comments" label="Comments" required />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  <FormActions.CancelButton
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

const WorkPlanCommonFields = (props) => {
  const {projectId, isNew, isReadOnly, selectProject, ...rest} = props;

  const validateDates = async (value, values, field) => {
    if (!field.dirty) return;
    if (!value) return "Required";
    if (value === "Invalid Date") return "Invalid Date";
    if (values.start_date && values.end_date && moment(values.start_date).isAfter(values.end_date)) {
      return "Start date must be before end date";
    }
  };
  return (
    <>
      <Grid item xs={12} sm={4}>
        <SimpleSelect
          name="type"
          label="Work Plan Type"
          options={workPlanTypes}
          placeholder="Select Work Plan Type"
          disabled={isReadOnly}
          required
          autoFocus
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextFieldMui
          name="scope_of_work"
          label="Scope of Work"
          required
          disabled={isReadOnly}
          helperText="Briefly describe the work being done"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <EmployeeAutoselectMui name="created_by" label="Created By" disabled />
      </Grid>

      {isNew && selectProject && (
        <Grid item xs={12} sm={4}>
          <ProjectAutoselectMui label="Project" name="project" required />{" "}
        </Grid>
      )}

      <Grid item xs={12} sm={4}>
        <ProjectCompanyAutoselectMui
          name="subcontractor"
          projectId={projectId}
          label="Subcontractor"
          disabled={!isNew || isReadOnly || !projectId}
          required
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <DatePickerMui
          name="start_date"
          label="Start Date"
          disabled={isReadOnly}
          required
          disablePast
          fieldProps={{
            validate: validateDates,
          }}
          validateFields={["end_date"]}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <DatePickerMui
          name="end_date"
          label="End Date"
          disabled={isReadOnly}
          required
          disablePast
          fieldProps={{
            validate: validateDates,
          }}
          validateFields={["start_date"]}
        />
      </Grid>
      {!isNew && (
        <Grid item xs={12} sm={4}>
          <EmployeeAutoselectMui name="approver" label="Approved By" disabled />{" "}
        </Grid>
      )}
      <Grid item xs={12} sm={4}>
        <CheckboxMui name="is_expedited" label="Expedited Request" disabled={isReadOnly} />
      </Grid>
    </>
  );
};

export const WorkPlanDialogForm = (props: {
  isNew?: boolean;
  isOpen: boolean;
  onSubmit: (values: any) => void;
  handleClose: () => void;
  selectProject?: boolean;
  [rest: string]: any;
}) => {
  const {isOpen, onSubmit, handleClose, isNew, selectProject, ...rest} = props;
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          handleClose();
        }}
        maxWidth="md"
        fullWidth
      >
        <FinalForm onSubmit={onSubmit} keepDirtyOnReinitialize {...rest}>
          {({handleSubmit, form, submitting, pristine, values, errors, dirtyFields}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">{`${isNew ? "Create" : "Update"} Work Plan`}</DialogTitle>
                <DialogContent>
                  {/* <BeforeUnload block={!pristine} /> */}
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={1}>
                      <WorkPlanCommonFields
                        isNew={isNew}
                        projectId={values?.project?.id}
                        isReadOnly={values.is_closed}
                        selectProject={selectProject}
                      />
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveAndEditButton disabled={submitting || pristine} color="primary" />
                  <FormActions.CancelButton
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const ApproveWorkPlanDialogForm = (props) => {
  const {isOpen, onSubmit, handleClose, ...rest} = props;

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          handleClose();
        }}
        maxWidth="md"
        fullWidth
      >
        <FinalForm onSubmit={onSubmit} keepDirtyOnReinitialize {...rest}>
          {({handleSubmit, form, submitting, pristine, values, errors, dirtyFields}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Approve Work Plan</DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <strong>AI review is not authoritative. Be sure to review the work plan thoroughly.</strong>{" "}
                        Ensure that all information is complete and correct before approving. The work plan will be
                        read-only after approval.
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui
                          name="approver_comments"
                          label="Approver Comments"
                          minRows={3}
                          multiline
                          required
                        />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} label="Approve" />
                  <FormActions.CancelButton
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const ReviseWorkPlanDialogForm = (props) => {
  const {isOpen, onSubmit, handleClose, ...rest} = props;

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          handleClose();
        }}
        maxWidth="md"
        fullWidth
      >
        <FinalForm onSubmit={onSubmit} keepDirtyOnReinitialize {...rest}>
          {({handleSubmit, form, submitting, pristine, values, errors, dirtyFields}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Work Plan Revise and Resubmit</DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        Mark this work plan <strong>Revise Resubmit?</strong> The super will be able to edit the work
                        plan and resubmit it for review.
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui name="approver_comments" label="Comments" minRows={3} multiline required />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} label="Save" />
                  <FormActions.CancelButton
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};
